<template>
  <div id="">
    <Field
      v-model="id"
      error
      required
      label="用户ID"
      placeholder="请输入ID"
      maxlength="6"
      type="number"
    />

    <Button @click="login" type="primary" block>发布</Button>
  </div>
</template>

<script>
import { Button, Field, Toast } from "vant";
import { get } from "@get/http";
export default {
  components: {
    Button,
    Field,
  },
  data: () => ({
    id: "",
  }),
  methods: {
    async login() {
      let data = await get(
        "/Login/c8e19436ab2822616b807acf32d12d",

        {
          params: {
            e3799919e40c1fec47665e837704a: this.id,
          },
        }
      );

      this.$store.dispatch("doUserInfo", data.info);
      this.$store.dispatch("doToken", data.token);
      this.$router.push({
        name: "HomeIndex",
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
